import styled from 'styled-components';

export default styled.h1`
  font-family: ffvCorporateFat;
  font-size: 2rem;
  letter-spacing: -0.7px;
  margin: 2rem 0 1rem 0;
  text-align: ${props => props.textAlign || 'center'};
  color: ${props => (props.theme.black ? 'white' : 'black')};
`;

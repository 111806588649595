import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import intersection from 'lodash/intersection';
import sampleSize from 'lodash/sampleSize';
import take from 'lodash/take';

import ProjectCard from './ProjectCard';
import Separator from './Separator';
import Subheading from './Subheading';

const removeSelf = (arr, id) => arr.filter(e => e.ID !== id);

const filterCategories = (arr, cats) =>
  arr.filter(elem => intersection(elem.metadata.categories, cats).length);

const sampleThree = (filtered, whole) => {
  const filteredSample = sampleSize(filtered, 3);
  if (filteredSample.length >= 3) {
    return filteredSample;
  }

  return filteredSample.concat(sampleSize(whole, 3 - filteredSample.length));
};

export default ({ metaArray, myId, myCategories }) => {
  const withoutSelf = removeSelf(metaArray, myId);
  const filteredByCats = filterCategories(withoutSelf, myCategories);
  // const sampled = sampleThree(filteredByCats, metaArray);
  const sampledInitially = take(metaArray, 3);

  const [sampled, setSampled] = useState(sampledInitially);
  useEffect(() => {
    setSampled(sampleThree(filteredByCats, metaArray));
  }, []);
  return (
    <>
      <Separator height={3} />
      <Subheading>More Work</Subheading>
      <Grid container justify="center" spacing={24}>
        {sampled.map((meta, i) => (
          <Grid item xs={10} sm={4} key={meta.ID + meta.title}>
            <ProjectCard
              id={meta.ID}
              src={meta.featured_image || ''}
              title={meta.title}
              categories={meta.metadata.categories}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

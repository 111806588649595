import React from 'react';
import styled from 'styled-components';

import AspectRatioContainer from '../AspectRatioContainer';
import StyledStageContainer from './StyledStageContainer';
import Video from '../Video';
import WPImage from '../WPImage';

const Constrain = styled.div`
  max-width: 800px;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  z-index: 5;
`;

const Img1 = styled.div`
  width: 30%;
  top: 8rem;
  left: 0;
  position: absolute;
  z-index: 9;
`;
const Img3 = styled.div`
  width: auto;
  right: 2rem;
  bottom: 4rem;
  position: absolute;
  z-index: 1;
  @media all and (max-width: 550px) {
    bottom: 6rem;
  }
`;

export default ({ data, stageID, current }) => {
  if (
    !(
      data['1'] &&
      data['2'] &&
      (data['3_image'] || data['3_video']) &&
      data['4']
    )
  )
    return null;
  return (
    <StyledStageContainer>
      <AspectRatioContainer width="100%" aspectRatio={1.777}>
        <Background>
          <WPImage
            src={data['1'].url}
            srcSet={data['1'].sizes}
            sizes={'100vw'}
            cover
          />
        </Background>
        <Constrain>
          <Hero>
            <AspectRatioContainer width="95vh" aspectRatio={1.777}>
              {!data['3_video'].hd && (
                <WPImage
                  src={data['3_image'] && data['3_image'].url}
                  cover
                  srcSet={data['3_image'].sizes}
                  sizes={'(max-width: 1000px) 100vw, 60vw'}
                />
              )}
              {data['3_video'].hd && (
                <Video
                  sd={data['3_video'] && data['3_video'].sd}
                  hd={data['3_video'] && data['3_video'].hd}
                  poster={data['3_video'] && data['3_video'].fallback_image}
                  loop
                  autoPlay
                  noMouseOver
                />
              )}
            </AspectRatioContainer>
          </Hero>
          <Img1>
            <AspectRatioContainer width="30vh" aspectRatio={0.75}>
              <WPImage
                src={data['2'].url}
                srcSet={data['2'].sizes}
                sizes={'30vw'}
                cover
              />
            </AspectRatioContainer>
          </Img1>
          <Img3>
            <AspectRatioContainer width="20vh" aspectRatio={0.75}>
              <WPImage
                src={data['4'].url}
                srcSet={data['4'].sizes}
                sizes={'30vw'}
                cover
              />
            </AspectRatioContainer>
          </Img3>
        </Constrain>
      </AspectRatioContainer>
    </StyledStageContainer>
  );
};

import React from 'react';

import Content from '../Content';
import Layout from '../Layout';
import MoreWork from '../MoreWork';
import ProjectHeader from '../ProjectHeader';
import ProjectStage from '../ProjectStage';
import Stage1 from '../stages/Stage1';
import Stage2 from '../stages/Stage2';
import Stage3 from '../stages/Stage3';
import Stage4 from '../stages/Stage4';
import Stage5 from '../stages/Stage5';

const renderStage = design => {
  if (design.use_stage) {
    switch (design.stage_selector) {
      case '1':
        return <Stage1 data={design.stage_a} />;
      case '2':
        return <Stage2 data={design.stage_b} />;
      case '3':
        return <Stage3 data={design.stage_c} />;
      case '4':
        return <Stage4 data={design.stage_d} />;
      case '5':
        return <Stage5 data={design.stage_e} />;
      default:
        return null;
    }
  } else {
    return (
      <ProjectStage
        background={design.background_image.url}
        bgSrcSet={design.background_image.sizes}
        video={design.header_video.poster_image ? true : false}
        image={design.header_video.poster_image ? false : true}
        hd={design.header_video.hd}
        sd={design.header_video.sd}
        overlay={design.header_video.fade_over_black}
        controls={design.header_video.show_controls_on_hover}
        poster={
          design.header_video.poster_image
            ? design.header_video.poster_image.url
            : ''
        }
        src={design.header_image && design.header_image.url}
        srcSet={design.header_image && design.header_image.sizes}
      />
    );
  }
};

export default ({ pageContext: { project, projectsMeta, searchIndex } }) => {
  const {
    title,
    id,
    acf: { metadata, design, content },
  } = project;

  return (
    <>
      <Layout background={design.black_background} searchIndex={searchIndex}>
        <ProjectHeader
          title={title.rendered}
          metadata={metadata}
          stage={renderStage(design)}
        />
        <Content content={content} />
        <MoreWork
          metaArray={projectsMeta}
          myId={id}
          myCategories={metadata.categories}
        />
      </Layout>
    </>
  );
};

import React from 'react';
import styled from 'styled-components';

import AspectRatioContainer from '../AspectRatioContainer';
import StyledStageContainer from './StyledStageContainer';
import Video from '../Video';
import WPImage from '../WPImage';

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  z-index: 5;
`;

const Img1 = styled.div`
  width: 20%;
  bottom: 2rem;
  right: 10rem;
  position: absolute;
  z-index: 4;
  @media all and (max-aspect-ratio: 3/2) {
    display: none;
  }
`;

export default ({ data, stageID, current }) => {
  if (!(data['1'] && (data['2_image'] || data['2_video']) && data['3']))
    return null;
  return (
    <StyledStageContainer>
      <AspectRatioContainer aspectRatio={1.7777}>
        <Background>
          <WPImage
            src={data['1'].url}
            srcSet={data['1'].sizes}
            sizes={'100vw'}
            cover
          />
        </Background>
        <Hero>
          <AspectRatioContainer width="100vh" aspectRatio={1.777}>
            {!data['2_video'].hd && (
              <WPImage
                src={data['2_image'].url}
                srcSet={data['2_image'].sizes}
                sizes={'(max-width: 900px) 100vw, 70vw'}
                cover
              />
            )}
            {data['2_video'].hd && (
              <Video
                sd={data['2_video'] && data['2_video'].sd}
                hd={data['2_video'] && data['2_video'].hd}
                poster={data['2_video'] && data['2_video'].fallback_image}
                loop
                autoPlay
                noMouseOver
              />
            )}
          </AspectRatioContainer>
        </Hero>
        <Img1>
          <AspectRatioContainer aspectRatio={0.75}>
            <WPImage
              src={data['3'].url}
              srcSet={data['3'].sizes}
              sizes={'50vw'}
              cover
            />
          </AspectRatioContainer>
        </Img1>
      </AspectRatioContainer>
    </StyledStageContainer>
  );
};

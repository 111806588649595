import React from 'react';
import styled from 'styled-components';

import AspectRatioContainer from '../AspectRatioContainer';
import StyledStageContainer from './StyledStageContainer';
import WPImage from '../WPImage';

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Hero = styled.div`
  max-width: 80%;
  z-index: 5;
`;

const Img1 = styled.div`
  width: 27%;
  top: 5rem;
  left: 12rem;
  position: absolute;
  z-index: 4;
  @media all and (max-width: 700px) {
    display: none;
  }
`;

const Img3 = styled.div`
  max-width: 80%;
  width: 100vh;
  right: 7rem;
  top: 4rem;
  position: absolute;
  z-index: 3;
`;

export default ({ data, current, stageID }) => {
  if (!(data['1'] && data['2'] && data['4'] && data['3'])) return null;
  return (
    <StyledStageContainer>
      <AspectRatioContainer aspectRatio={1.777}>
        <Background>
          <WPImage
            src={data['1'].url}
            srcSet={data['1'].sizes}
            sizes={'100vw'}
            cover
          />
        </Background>
        <Hero>
          <AspectRatioContainer width="40vh" aspectRatio={0.75}>
            <WPImage
              src={data['2'].url}
              srcSet={data['2'].sizes}
              sizes={'80vw'}
              cover
            />
          </AspectRatioContainer>
        </Hero>
        <Img1>
          <AspectRatioContainer aspectRatio={0.75}>
            <WPImage
              src={data['3'].url}
              srcSet={data['3'].sizes}
              sizes={'80vw'}
              cover
            />
          </AspectRatioContainer>
        </Img1>
        <Img3>
          <AspectRatioContainer aspectRatio={1.78}>
            <WPImage
              src={data['4'].url}
              srcSet={data['4'].sizes}
              sizes={'80vw'}
              cover
            />
          </AspectRatioContainer>
        </Img3>
      </AspectRatioContainer>
    </StyledStageContainer>
  );
};

import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

import OriginalH1 from './H1';
import OriginalInternalLink from './InternalLink';

const Container = styled.div`
  // margin-top: 1rem;
  padding: 2rem 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${props => (props.theme.black ? 'white' : 'black')};
  @media all and (max-width: 700px) {
    display: block;
    & > div {
      width: 100%;
    }
  }
`;

const TitleContainer = styled.div`
  width: 60%;
`;

const H1 = styled(OriginalH1)`
  margin-top: 0;
  font-size: 3rem;
  @media all and (max-width: 1280px) {
    font-size: 2.5rem;
  }
`;

const MetaContainer = styled.div`
  padding-top: 0.5rem;
  width: 30%;
  font-family: ffvCorporateFat, sans-serif;
  padding-bottom: 2rem;
  @media all and (max-width: 700px) {
    margin-top: 2rem;
    padding-bottom: 0;
  }
`;

const MetaData = styled.dl`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 35% auto;
  grid-column-gap: 1rem;
  grid-row-gap: 0.3rem;
  line-height: 1.2rem;
  & dt {
    grid-column: 1 / 2;
  }
  & dd {
    grid-column: 2 / 3;
    margin: 0;
  }
`;

const DD = styled.dd`
  font-family: ffvGaramond, serif;
`;

const InternalLink = styled(OriginalInternalLink)`
  margin-right: 0.3rem;
  padding-top: 0;
`;

export default class ProjectHeader extends React.Component {
  render() {
    const { metadata } = this.props;
    return (
      <Container>
        <TitleContainer>
          <H1
            textAlign="left"
            dangerouslySetInnerHTML={{ __html: this.props.title }}
          ></H1>
        </TitleContainer>
        {this.props.stage}
        <MetaContainer>
          <MetaData>
            {metadata.official_project_title && (
              <>
                <dt>Project</dt>
                <DD>{metadata.official_project_title}</DD>
              </>
            )}
            <dt>Client</dt>
            <DD>{metadata.client}</DD>
            {metadata.lead_agency && (
              <>
                <dt>Lead</dt>
                <DD>{metadata.lead_agency}</DD>
              </>
            )}
            <dt>Services</dt>
            <DD>
              {metadata.services &&
                metadata.services.map(({ ID, post_title }, i, arr) => (
                  <Link to={`/services/${ID}`} key={ID}>
                    <InternalLink small>{post_title}</InternalLink>
                  </Link>
                ))}
            </DD>
          </MetaData>
        </MetaContainer>
      </Container>
    );
  }
}

import React from 'react';
import styled from 'styled-components';

import AspectRatioContainer from '../AspectRatioContainer';
import StyledStageContainer from './StyledStageContainer';
import Video from '../Video';
import WPImage from '../WPImage';

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Hero = styled.div`
  max-width: 80%;
  z-index: 5;
`;

const Img1 = styled.div`
  width: 30%;
  top: 6rem;
  left: 50%;
  transform: translate(-110%);
  position: absolute;
  z-index: 6;
  @media all and (max-width: 700px) {
    display: none;
  }
`;

const Img3 = styled.div`
  max-width: 70%;
  left: 50%;
  transform: translate(-20%);
  top: 25%;
  position: absolute;
  z-index: 7;
`;

export default ({ data, stageID, current }) => {
  if (
    !(
      data['1'] &&
      data['2'] &&
      (data['4_image'] || data['4_video']) &&
      data['3']
    )
  )
    return null;
  return (
    <StyledStageContainer>
      <AspectRatioContainer width="100%" aspectRatio={1.777}>
        <Background>
          <WPImage
            src={data['1'].url}
            srcSet={data['1'].sizes}
            sizes={'100vw'}
            cover
          />
        </Background>
        <Hero>
          <AspectRatioContainer width="60vh" aspectRatio={1}>
            <WPImage
              src={data['3'].url}
              srcSet={data['3'].sizes}
              sizes={'(max-widht: 800px) 100vw, 50vw'}
              cover
            />
          </AspectRatioContainer>
        </Hero>
        <Img1>
          <AspectRatioContainer aspectRatio={1.5}>
            <WPImage
              src={data['2'].url}
              srcSet={data['2'].sizes}
              sizes={'50vw'}
              cover
            />
          </AspectRatioContainer>
        </Img1>
        <Img3>
          <AspectRatioContainer width="80vh" aspectRatio={1.778}>
            {!data['4_video'].hd && (
              <WPImage
                src={data['4_image'].url}
                srcSet={data['4_image'].sizes}
                sizes={'50vw'}
                cover
              />
            )}
            {data['4_video'].hd && (
              <Video
                sd={data['4_video'] && data['4_video'].sd}
                hd={data['4_video'] && data['4_video'].hd}
                poster={data['4_video'] && data['4_video'].fallback_image}
                loop
                autoPlay
                noMouseOver
              />
            )}
          </AspectRatioContainer>
        </Img3>
      </AspectRatioContainer>
    </StyledStageContainer>
  );
};
